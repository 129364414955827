import './Footer.css';
import OdysseusLogo from '../assets/icons/odysseus-logo.png';
import GoldSopoLogo from '../assets/icons/gold-sopo-logo.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigates = useNavigate();
    const navigate = (param) => {
        navigates(param);
    };
    return (
        <div className="footer">
            <div className="footer-inner-container footer-desktop">
                <div className="f">
                    <ul className="left-list">
                        <li className="footer-li" onClick={() => navigate('/')}>
                            Home
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('howtoplay')}
                        >
                            How To Play
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('web3integration')}
                        >
                            WEB3
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('socialfeatures')}
                        >
                            Features
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('certifiedfair')}
                        >
                            Certifications
                        </li>
                    </ul>
                </div>
                <div className="footer-logos">
                    <div>
                        <img src={GoldSopoLogo} className='logo' alt="logo"/>
                    </div>
                    <div>
                        <img src={OdysseusLogo} className='logo' alt="logo"/>
                    </div>
                </div>
                <div className="f">
                    <ul className="right-list">
                        <li
                            className="footer-li"
                            onClick={() => navigate('b2b-solution')}
                        >
                            B2B Solutions
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('terms-of-service')}
                        >
                            Terms of Service
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('privacy-policy')}
                        >
                            Privacy Policy
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('contact-us')}
                        >
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer-inner-container footer-mobile">
                <div className="f">
                    <div className="mobile-footer-logos">
                        <img src={GoldSopoLogo} className='logo' alt="logo"/>
                        <img src={OdysseusLogo} style={{ height: '60px' }} alt="logo-odysseus"/>
                    </div>
                    <ul>
                        <div className="footer-list-title">Quick Links</div>
                        <li
                            className="footer-li"
                            onClick={() => navigate('/')}
                        >
                            Home
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('howtoplay')}
                        >
                            How To Play
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('web3integration')}
                        >
                            WEB3
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('socialfeatures')}
                        >
                            Features
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('certifiedfair')}
                        >
                            Certifications
                        </li>
                    </ul>
                    <ul>
                        <div className="footer-list-title">More</div>
                        <li
                            className="footer-li"
                            onClick={() => navigate('b2b-solution')}
                        >
                            B2B Solutions
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('terms-of-service')}
                        >
                            Terms of Service
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('privacy-policy')}
                        >
                            Privacy Policy
                        </li>
                        <li
                            className="footer-li"
                            onClick={() => navigate('contact-us')}
                        >
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
            <div className="description">
                <p>
                    The sweepstakes accessible via the SOPO app is solely
                    sponsored and administered by the creators of SOPO, Odysseus
                    Software Company, Inc. Apple is not a sponsor of, affiliated
                    with, or responsible for conducting, the sweepstakes.
                    Odysseus Software Company, Inc. All right reserved.
                </p>
            </div>
        </div>
    );
};

export default Footer;
