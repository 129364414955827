import './SopoPlus.css';
import Column from '../../components/Layouts/Column';
import events from '../../assets/images/sopoPlus/events.png';
import socialPlus from '../../assets/images/sopoPlus/sopoHeader.png';
import prizes from '../../assets/images/sopoPlus/prizes.png';
import stake from '../../assets/images/sopoPlus/stake.png';
import tournaments from '../../assets/images/sopoPlus/tournaments.png';
import sk from '../../assets/images/sopoPlus/stakekings.png';
const Row = ({ children }) => {
  return <div className="row-plus">{children}</div>;
};
const data = {
  das: 'ASd',
  asd: 'sda',
  asd: 'sda',
};
const SopoPlus = () => {
  return (
    <>
      <div className='sopo-plus-c'>
        <Row>
          <Column>
            <div>
              <div className='social-plus-d'>
              <img src={socialPlus} style={{ maxWidth: '520px' }} /></div>
              <div class="column-description">
                Introducing the ultimate SOPO experience. Subscribe for just
                $0.99 to unlock exclusive benefits unavailable to other players.
              </div>
              <div className="big-img-mobile">
              <img src={events} style={{ maxWidth: '800px' }} />
            </div>
            <div className='tournament-staking'>
              <Row>
                <Column>
                  <img style={{ maxWidth: '200px' }} src={tournaments} />
                </Column>
                <Column>
                  <img style={{ maxWidth: '200px' }} src={stake} />
                </Column>
                <Column>
                  <img style={{ maxWidth: '200px' }} src={prizes} />
                </Column>
              </Row>
              </div>
              <div>
                <button className="play-now-btn play-now-btn-sopoPlus" onClick={()=>window.open("https://app.playsopo.com/link/open")}>
                  Subscribe now
                </button>
              </div>
              <p style={{ color: '#fff' }}>
                Marketing & Prize
                <br />
                Offering Partner
              </p>
              <img className='sk' style={{ maxWidth: '73px' }} src={sk} />
            </div>
          </Column>
          <Column>
            <div className="carousel-text">
              <img src={events} style={{ maxWidth: '800px' }} />
            </div>
          </Column>
        </Row>
        <div className='s-p-bottom-p'>
        <p
          style={{
            color: '#fff',
            maxWidth: '1100px',
            margin: '0 auto',
            paddingBottom: '20px',
            fontSize: '14px',
          }}
        >
          © 2022 playsopo.com | All Rights Reserved.
          <br />
          SOPO+ is owned and operated by Odysseus Software Company, Inc.,
          registration number, registered address. Contact us at
          support@playsopo.com NO PURCHASE NECESSARY to enter Sweepstakes.
          SWEEPSTAKES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see
          terms of service.
        </p>
        </div>
      </div>
    </>
  );
};

export default SopoPlus;
